export const DESCRIPTION_MAX_LENGTH = 5000
export const DESCRIPTION_MIN_LENGTH = 20
export const ZENDESK_REQUEST_TAG = 'frm-tst-df-062022'
export const ZENDESK_FOLLOW_UP_REQUEST_TAG = 'form-follow-up-request'
export const ORGANIZATION_ACCOUNT_TAG = 'org-account'
export const FALLBACK_SUPPORT_TAG = 'ticket-fallback'
export const CHAT_CLOSED_TAG = 'chat-schedule-closed'
export const ELEMENT_COUNT = 6
export const DROPDOWN_BREAKPOINT = 12
export const FIELD_FALLBACK_VALUE_NA = 'n/a'
export const MAX_TRUNCATION_LENGTH = 75

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import UploadIcon from 'assets/svgs/upload.svg'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { setVisibleModal } from 'modules/modals'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { useUmrDevicesQuery } from 'store/queries/useUmrDevicesQuery'
import { useGeoLocationQuery } from 'store/queries/useGeoLocationQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { QueryKey } from 'store/types'
import { useCreateOrUpdateZendeskUser } from 'store/mutations/zendesk/useCreateOrUpdateZendeskUser'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { BrowserUtils } from 'utils/browserUtils'
import { withScoreCheck } from 'utils/withScoreCheck'
import {
  getMetaFields,
  getSupportFileFromId,
  getUmrSupportFileFromId,
} from './utils'
import {
  SECURE_ATTACHMENTS_MODAL_ID,
  WrappedSecureAttachmentsModal,
} from './SecureAttachmentsModal'
import {
  FALLBACK_SUPPORT_MODAL_ID,
  WrappedFallbackSupportModal,
} from './FallbackSupportModal'

interface AdaState {
  isInitiated: boolean
  metaFields: Record<string, string>
}

const useAda = () => {
  const queryClient = useQueryClient()
  const { profileData } = useSsoProfileQuery()
  const { validUcoreDevices, areNcaDevicesFetched } = useNcaDevicesQuery()
  const { umrDevices, isUmrDevicesFetched } = useUmrDevicesQuery()
  const { locationInfo } = useGeoLocationQuery()
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const { createOrUpdateZendeskUser } = useCreateOrUpdateZendeskUser()
  const { accountType } = useOrganizationsQuery()
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const shouldAdaOpenAutomatically = BrowserUtils.getQueryValue(
    window.location.href,
    'support'
  )
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const ada = queryClient.getQueryData<AdaState>([QueryKey.ADA])

  const adaFrame = $('#ada-entry')

  const adaChatFrame = $('#ada-chat-frame')
  const adaFileUploadDropzone = $('.FileUploadDropzone')
  const secureAttachmentsButton = $('#secure-attachments-button')

  useEffect(() => {
    secureAttachmentsButton.on('click', () => {
      dispatch(setVisibleModal(SECURE_ATTACHMENTS_MODAL_ID))
    })

    if (adaChatFrame.length > 0) {
      adaChatFrame.css('bottom', '60px !important')
    }
    if (adaChatFrame.length > 0 && !secureAttachmentsButton.length) {
      const isMobileDevice =
        /(iPhone)|(iPod)|(android)|(webOS)/i.exec(navigator.userAgent) !== null

      $(
        `<button id="secure-attachments-button"${isMobileDevice ? ' class="full-screen"' : ''}><img src="${UploadIcon}" />Upload Secure File</button>`
      ).insertAfter(adaChatFrame)
    }
    if (isOpen && secureAttachmentsButton.hasClass('hidden')) {
      secureAttachmentsButton.removeClass('hidden')
    }
    if (!isOpen && !secureAttachmentsButton.hasClass('hidden')) {
      secureAttachmentsButton.addClass('hidden')
    }
  }, [
    adaChatFrame,
    adaFileUploadDropzone.length,
    dispatch,
    isOpen,
    secureAttachmentsButton,
  ])

  useEffect(() => {
    if (adaFrame.length === 0 && isDoneAuthenticating) {
      window.adaEmbed.start({
        conversationEndCallback: () => {
          refetchOpenTickets()
        },
        chatterTokenCallback: () => {
          createOrUpdateZendeskUser()
        },
        toggleCallback: (isDrawerOpen: boolean) => setIsOpen(isDrawerOpen),
        handle: 'ubiquiti',
        eventCallbacks: {
          ['file_share_user_consent']: async (event: Record<string, any>) => {
            const consoleId = event.user_data.global.consoleID
            const hasConsent = event.user_data.global.fileSharingConsent

            if (hasConsent === 'Yes, I consent' && consoleId) {
              const { downloadUrl } = await getSupportFileFromId(consoleId)

              queryClient.setQueryData([QueryKey.ADA], (prev: AdaState) => ({
                ...prev,
                metaFields: {
                  ...prev.metaFields,
                  support_file_url: downloadUrl,
                },
              }))

              const data = queryClient.getQueryData<AdaState>([QueryKey.ADA])

              window.adaEmbed.setMetaFields(
                data?.metaFields
                  ? data.metaFields
                  : { support_file_url: downloadUrl }
              )
            }
          },
          ['file_share_user_consent_umr']: async (
            event: Record<string, any>
          ) => {
            const consoleId = event.user_data.global.umr_consoleId
            const hasConsent = event.user_data.global.fileSharingConsent

            if (hasConsent === 'Yes, I consent' && consoleId) {
              const { download_url } = await getUmrSupportFileFromId(consoleId)

              queryClient.setQueryData([QueryKey.ADA], (prev: AdaState) => ({
                ...prev,
                metaFields: {
                  ...prev.metaFields,
                  umr_support_file_url: download_url,
                },
              }))

              const data = queryClient.getQueryData<AdaState>([QueryKey.ADA])

              window.adaEmbed.setMetaFields(
                data?.metaFields
                  ? data.metaFields
                  : { umr_support_file_url: download_url }
              )
            }
          },
        },
        adaReadyCallback: () => {
          queryClient.setQueryData([QueryKey.ADA], (prev: AdaState) => ({
            ...prev,
            isInitiated: true,
          }))
        },
      })
    }

    if (adaFrame.length > 0 && !isDoneAuthenticating) {
      window.adaEmbed.stop()
      queryClient.setQueryData([QueryKey.ADA], {
        metaFields: {},
        isInitiated: false,
      })
    }
  }, [
    adaFrame,
    createOrUpdateZendeskUser,
    isDoneAuthenticating,
    queryClient,
    refetchOpenTickets,
  ])

  useEffect(() => {
    if (
      areNcaDevicesFetched &&
      profileData &&
      isUmrDevicesFetched &&
      locationInfo &&
      accountType &&
      ada?.isInitiated
    ) {
      const metaFieldsToAdd = getMetaFields(
        validUcoreDevices,
        umrDevices,
        profileData,
        accountType === AccountType.ORG_OWNER,
        locationInfo.country_name
      )
      queryClient.setQueryData([QueryKey.ADA], (prev: AdaState) => ({
        ...prev,
        metaFields: {
          ...prev.metaFields,
          ...metaFieldsToAdd,
        },
      }))

      const data = queryClient.getQueryData<AdaState>([QueryKey.ADA])

      window.adaEmbed.setMetaFields(
        data?.metaFields ? data.metaFields : metaFieldsToAdd
      )
    }
  }, [
    accountType,
    ada?.isInitiated,
    areNcaDevicesFetched,
    isDoneAuthenticating,
    isUmrDevicesFetched,
    locationInfo,
    profileData,
    queryClient,
    refetchOpenTickets,
    umrDevices,
    validUcoreDevices,
  ])

  useEffect(() => {
    const openAda = async () => {
      const info = await window.adaEmbed.getInfo()

      if (!info.isDrawerOpen) {
        window.adaEmbed.toggle()
      }
    }

    if (shouldAdaOpenAutomatically && ada?.isInitiated) {
      openAda()
      const newUrl = BrowserUtils.deleteQueryValue(
        window.location.href,
        'support'
      )
      window.history.replaceState({}, '', newUrl)
    }
  }, [ada?.isInitiated, shouldAdaOpenAutomatically])
}

const WithAda: React.FC = () => {
  useAda()
  const dispatch = useDispatch()
  const openFallbackSupportModal = BrowserUtils.getQueryValue(
    window.location.href,
    'supportfallback'
  )

  useEffect(() => {
    if (openFallbackSupportModal) {
      dispatch(setVisibleModal(FALLBACK_SUPPORT_MODAL_ID))
      const newUrl = BrowserUtils.deleteQueryValue(
        window.location.href,
        'supportfallback'
      )
      window.history.replaceState({}, '', newUrl)
    }
  }, [dispatch, openFallbackSupportModal])

  return (
    <>
      <WrappedSecureAttachmentsModal />
      <WrappedFallbackSupportModal />
    </>
  )
}

export default withScoreCheck(null, WithAda)

import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { NcaDevicesApi } from 'api/nca/ncaDevices'
import { UcoreDevice } from 'api/nca/types'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from '../types'

const UNCATEGORIZED = 'Uncategorized'

const FALLBACK_SHADOW_DATA: UcoreDevice['shadow'] = {
  deviceId: UNCATEGORIZED,
  state: {
    reported: {
      releaseChannel: UNCATEGORIZED,
      auth_token: UNCATEGORIZED,
      controller_uuid: UNCATEGORIZED,
      controllers: [],

      host_type: 0,
      hostname: UNCATEGORIZED,
      ipAddrs: [UNCATEGORIZED],

      mac: UNCATEGORIZED,
      mgmt_port: 0,
      name: UNCATEGORIZED,
      version: UNCATEGORIZED,
      hardware: {
        bom: UNCATEGORIZED,
        firmwareVersion: UNCATEGORIZED,
        hwrev: 0,
        name: UNCATEGORIZED,
        qrid: UNCATEGORIZED,
        reboot: UNCATEGORIZED,
        serialno: UNCATEGORIZED,
        shortname: UNCATEGORIZED,
        subtype: UNCATEGORIZED,
        sysid: 0,
        upgrade: UNCATEGORIZED,
        uuid: UNCATEGORIZED,
        'cpu.id': UNCATEGORIZED,
      },
      deviceState: UNCATEGORIZED,
    },
  },
}

export const useNcaDevicesQuery = (
  api: NcaDevicesApi = new NcaDevicesApi()
) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const ncaDevices = useQuery({
    queryKey: [QueryKey.NCA_DEVICES],
    queryFn: api.getAll,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const validUcoreDevices = useMemo(() => {
    const { data: devices } = ncaDevices

    if (!devices || !devices.length) return []
    return devices.filter((device): device is UcoreDevice => {
      if (
        !device?.shadow?.state?.reported?.name ||
        device.shadow.state.reported.version === 'Uncategorized' ||
        device.type !== 'ucore'
      ) {
        // filter out corrupted data, because they will not show up properly
        return false
      }

      return true
    })
  }, [ncaDevices])

  const ownedUcoreDevices = useMemo(() => {
    const { data: devices } = ncaDevices

    if (!devices || !devices.length) return []
    return devices.filter((device, i): device is UcoreDevice => {
      if (!device?.shadow?.state?.reported?.name) {
        device.shadow = { ...FALLBACK_SHADOW_DATA, deviceId: i.toString() }
      }

      return (device as UcoreDevice).type === 'ucore' && device.owner
    })
  }, [ncaDevices])

  return {
    ncaDevices: ncaDevices.data,
    areNcaDevicesLoading: ncaDevices.isFetching,
    areNcaDevicesFetched: ncaDevices.isFetched,
    validUcoreDevices,
    ownedUcoreDevices,
  }
}

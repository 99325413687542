import {
  BLACKLISTED_COUNTRY,
  FIFTEEN_SECS_IN_MILLISECONDS,
} from 'pages/constants'

const {
  api: { helpCenter },
} = __CONFIG__

export const isIpBlacklisted = (country: string) => {
  const blacklistedCountries = Object.values<string>(BLACKLISTED_COUNTRY)
  const isBlacklisted = blacklistedCountries.includes(country)

  return { isBlacklisted }
}

export const redirectAfterSpecifiedTime = (
  time = FIFTEEN_SECS_IN_MILLISECONDS
) => {
  return setTimeout(() => {
    window.location.href = helpCenter.base
  }, time)
}

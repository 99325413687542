import React, { SetStateAction, useMemo, useState } from 'react'
import { api } from 'api'
import { useFormik } from 'formik'
import { difference } from 'lodash-es'
import { useQueryClient } from '@tanstack/react-query'
import { Bubble } from 'components/support/Bubble'
import { Footer } from 'components/support/Footer'
import { SendSafelyDropzone } from 'components/support/SendSafelyDropzone'
import { Form } from 'components/support/Wrappers'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { useUmrDevicesQuery } from 'store/queries/useUmrDevicesQuery'
import { QueryKey } from 'store/types'
import { useZendeskUserQuery } from 'store/queries/useZendeskUserQuery'
import { BillingFormValidationSchema } from './config/billingFormValidationSchema'
import { getConfig } from './config/formConfig'
import { SupportConsole, SupportFormState } from './types'
import { useSendSafely } from '../useSendSafely'
import { handleCreateRequestError } from '../utils'
import { renderBillingSection } from './utils/nextBillingSection'
import { useFormatTicket } from './utils/useFormatTicket'

type Props = {
  dropzoneId: string
  setIsFormSubmitted: React.Dispatch<SetStateAction<boolean>>
  followUpTicketId?: string
}

export const BillingForm: React.FC<Props> = ({
  dropzoneId,
  setIsFormSubmitted,
  followUpTicketId,
}) => {
  const queryClient = useQueryClient()
  const { hasZendeskUser } = useZendeskUserQuery()
  const { validUcoreDevices: consoles, areNcaDevicesLoading } =
    useNcaDevicesQuery()
  const { umrDevices, isUmrDevicesLoading } = useUmrDevicesQuery()

  const config = getConfig(
    consoles,
    areNcaDevicesLoading,
    umrDevices,
    isUmrDevicesLoading
  )

  const [visibleSections, setVisibleSections] = useState<string[]>([
    'billingSpecificService',
  ])
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false)
  const { handleSendSafelyUpload } = useSendSafely(
    dropzoneId,
    setIsAttachmentUploading
  )
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const { formatRequestedTicket } = useFormatTicket(config)

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    isSubmitting,
    touched,
    setFieldTouched,
  } = useFormik<SupportFormState>({
    initialValues: {},
    validationSchema: BillingFormValidationSchema,
    onSubmit: async (values) => {
      try {
        const attachmentsUrl = await handleSendSafelyUpload()
        const formattedRequest = await formatRequestedTicket(
          values,
          selectedConsole,
          attachmentsUrl,
          followUpTicketId
        )
        if (!formattedRequest) return

        try {
          await api.createZendeskRequest(formattedRequest)
          if (!hasZendeskUser) {
            queryClient.invalidateQueries({
              queryKey: [QueryKey.ZENDESK_USER],
            })
          }
          refetchOpenTickets()
          setIsFormSubmitted(true)
        } catch (error) {
          handleCreateRequestError(
            error,
            setErrorMessage,
            formattedRequest,
            setIsFormSubmitted
          )
        }
      } catch (error) {
        console.error(error)
        setErrorMessage('SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION')
      }
    },
  })

  const selectedConsole = useMemo(
    () =>
      consoles?.find(
        (console) =>
          console.shadow?.state?.reported?.mac === values.console?.mac
      ),
    [consoles, values.console?.mac]
  )

  const clearFormikState = (updatedSections: string[]) => {
    const existingStateValues = Object.keys(values)
    difference(existingStateValues, updatedSections).forEach((key) =>
      setFieldValue(key, undefined)
    )
  }

  const handleVisibilities = (
    clickedSection: string,
    hasValue = true,
    nextSection?: string
  ) => {
    if (
      visibleSections.includes(clickedSection) &&
      visibleSections[visibleSections.length - 1] !== clickedSection
    ) {
      const indexToRemove = visibleSections.indexOf(clickedSection)
      const updatedArray = visibleSections.slice(0, indexToRemove + 1)
      clearFormikState(updatedArray)
      return hasValue && nextSection
        ? setVisibleSections([...updatedArray, nextSection])
        : setVisibleSections(updatedArray)
    }
    if (!nextSection) return
    return setVisibleSections((prev) => [...prev, nextSection])
  }

  const handleChange = (
    clickedSection: string,
    value?: string | boolean | SupportConsole,
    sectionToRender?: string
  ) => {
    setFieldValue(clickedSection, value)
    handleVisibilities(clickedSection, value !== undefined, sectionToRender)
  }

  const isFormValid =
    !isSubmitting && !Object.keys(errors).length && !!values.description

  return (
    <Form onSubmit={handleSubmit}>
      {visibleSections.map((section, i) =>
        renderBillingSection(section, {
          values,
          handleChange,
          errors,
          touched,
          setFieldTouched,
          shouldScroll: i + 1 === visibleSections.length && i > 0,
          config,
        })
      )}
      <Bubble
        question={config.attachments.fieldTitle}
        isVisible={visibleSections.includes('description')}
      >
        <SendSafelyDropzone id={dropzoneId} />
      </Bubble>
      <Footer
        isFormLoading={isSubmitting}
        isFormValid={isFormValid}
        errorMessage={errorMessage}
        isAttachmentUploading={isAttachmentUploading}
      />
    </Form>
  )
}

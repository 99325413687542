import { useEffect, useState } from 'react'
import { Country } from 'enums'
import { useGeoLocationQuery } from 'store/queries/useGeoLocationQuery'
import { UI_VERIFY_BLACKLIST_COUNTRIES } from './constants'

export const useVerifyCountryLimitation = () => {
  const [hasVerifyCountryLimitation, setHasVerifyCountryLimitation] =
    useState<boolean>(false)
  const { locationInfo } = useGeoLocationQuery()

  useEffect(() => {
    if (locationInfo) {
      setHasVerifyCountryLimitation(
        UI_VERIFY_BLACKLIST_COUNTRIES.includes(
          locationInfo.country_name as Country
        )
      )
    }
  }, [locationInfo])

  return { hasVerifyCountryLimitation }
}

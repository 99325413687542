import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { TicketsApi } from 'api/zendesk/tickets'
import { ZendeskTicketsResponse } from 'api/zendesk/types'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'
import { useZendeskUserQuery } from './useZendeskUserQuery'

export const useClosedTicketsQuery = (api: TicketsApi = new TicketsApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const { hasZendeskUser } = useZendeskUserQuery()

  const getNextPage = (
    lastPageData: ZendeskTicketsResponse,
    allPagesData: ZendeskTicketsResponse[],
    lastPage: number
  ) => {
    const totalFetched = allPagesData.flatMap((page) => page.tickets).length

    const { totalTickets } = lastPageData

    if (totalFetched < totalTickets) {
      return lastPage + 1
    }
  }

  const closedTicketsPages = useInfiniteQuery({
    queryKey: [QueryKey.CLOSED_TICKETS],
    queryFn: api.getAllClosed,
    initialPageParam: 1,
    getNextPageParam: getNextPage,
    enabled: isDoneAuthenticating && hasZendeskUser,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const closedTickets = useMemo(() => {
    if (!closedTicketsPages.data) {
      return []
    }

    return closedTicketsPages.data.pages.flatMap((page) => page.tickets)
  }, [closedTicketsPages.data])

  const totalClosedTickets = useMemo(
    () => closedTicketsPages.data?.pages[0].totalTickets ?? 0,
    [closedTicketsPages.data?.pages]
  )

  const hasNextPage = useMemo(
    () => closedTickets.length < totalClosedTickets,
    [closedTickets.length, totalClosedTickets]
  )

  return {
    closedTickets,
    areClosedTicketsFetched: closedTicketsPages.isFetched,
    areClosedTicketsLoading: closedTicketsPages.isFetching,
    closedTicketsError: closedTicketsPages.error,
    fetchMoreClosedTickets: closedTicketsPages.fetchNextPage,
    refetchClosedTickets: closedTicketsPages.refetch,
    hasMoreClosedTickets: hasNextPage,
    totalClosedTickets,
  }
}

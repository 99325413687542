import { useMemo } from 'react'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { NcaBackupsApi } from 'api/nca/ncaBackups'
import {
  DeviceBackups,
  DeviceBackupsWithShadow,
  UcoreDevice,
} from 'api/nca/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from '../types'
import { useNcaDevicesQuery } from './useNcaDevicesQuery'

export const useNcaBackupsQuery = (
  api: NcaBackupsApi = new NcaBackupsApi()
) => {
  const { ownedUcoreDevices } = useNcaDevicesQuery()

  const ncaBackups = useQuery({
    queryKey: [QueryKey.NCA_BACKUPS, ownedUcoreDevices],
    queryFn: () => api.getAll(ownedUcoreDevices),
    enabled: !!ownedUcoreDevices.length,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const backupsFromAllDevices = useMemo(() => {
    if (!ncaBackups.data) {
      return []
    }
    return ncaBackups.data.reduce(
      (result: DeviceBackupsWithShadow[], item: DeviceBackups) => {
        const resultCopy = result.slice()
        ownedUcoreDevices?.forEach((device: UcoreDevice) => {
          if (device.hardwareId === item.hardwareId) {
            resultCopy.push({ ...item, shadow: device.shadow })
          }
        })
        return resultCopy
      },
      []
    )
  }, [ncaBackups.data, ownedUcoreDevices])

  return {
    ncaBackups: ncaBackups.data,
    areNcaBackupsLoading: ncaBackups.isLoading,
    areNcaBackupsRefetching: ncaBackups.isFetching,
    backupsFromAllDevices,
    ncaBackupsError: ncaBackups.error,
  }
}

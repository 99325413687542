import React, { useContext, useEffect, useMemo, useState } from 'react'
import EmptyResult from '@ubnt/ui-components/EmptyResult/EmptyResult'
import { FormattedMessage } from 'react-intl'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button'
import { HeaderTab } from 'api/zendesk/types'
import supportImage from 'assets/images/support/supportHeader.png'
import supportImageLow from 'assets/images/support/supportHeader_low.png'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import styled from 'theme/styled'
import { RequestsContext } from './RequestsContext'

export const EmptyResults: React.FC = () => {
  const { statusToShow, searchData } = useContext(RequestsContext)
  const [imageLoaded, setImageLoaded] = useState(false)
  const { isMaxMobileXLarge } = useContext(MediaSizingContext)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = supportImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [])

  const titleId = useMemo<string>(() => {
    if (statusToShow === HeaderTab.CCD) {
      return 'SUPPORT_MY_REQUESTS_EMPTY_CCD'
    }
    if (statusToShow === HeaderTab.CLOSED) {
      return 'SUPPORT_MY_REQUESTS_EMPTY_CLOSED'
    }
    return 'SUPPORT_MY_REQUESTS_EMPTY_OPEN'
  }, [statusToShow])

  if (searchData.error) {
    return (
      <Wrapper>
        <EmptyResult
          title={<FormattedMessage id="GENERIC_ERROR_SEARCH_EMPTY" />}
          description={
            <FormattedMessage id="GENERIC_ERROR_SEARCH_EMPTY_DESCRIPTION" />
          }
        />
      </Wrapper>
    )
  }

  if (statusToShow === HeaderTab.OPEN) {
    return (
      <Container>
        <Column>
          <Text variant="heading-xlarge-1">
            <FormattedMessage id="SUPPORT_REQUESTS_EMPTY_TITLE" />
          </Text>
          <Text>
            <FormattedMessage id="SUPPORT_REQUESTS_EMPTY_DESCRIPTION" />
          </Text>
          <Button
            onClick={async () => {
              const info = await window.adaEmbed.getInfo()
              if (!info.isDrawerOpen) {
                window.adaEmbed.toggle()
              }
            }}
            variant="primary"
            style={{ marginTop: '8px' }}
          >
            <FormattedMessage id="COMMON_SIDE_NAV_SUPPORT_FORM" />
          </Button>
        </Column>
        {/* Todo: Ask Sam for a new image and update -> ideal a clip of the full background */}
        {!isMaxMobileXLarge && <HeaderImage $imageLoaded={imageLoaded} />}
      </Container>
    )
  }

  return (
    <Wrapper>
      <EmptyResult title={<FormattedMessage id={titleId} />} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 278px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
`

const Container = styled(Wrapper)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin: -16px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding: 24px 0 24px 24px;
  width: max-content;
  max-width: 332px;
`

const HeaderImage = styled.div<{
  $imageLoaded: boolean
}>`
  width: 100%;
  max-width: 540px;
  height: 278px;
  margin-right: -100px;
  background-size: cover;
  ${({ $imageLoaded }) => {
    if (!$imageLoaded) {
      return `
      -webkit-filter: blur(6px);
      -moz-filter: blur(6px);
      -o-filter: blur(6px);
      -ms-filter: blur(6px);
      filter: blur(6px);
      background-image: url(${supportImageLow})
    `
    }
    if ($imageLoaded) {
      return `
      background-image: url(${supportImage})

    `
    }
  }}
`

import { maxBy } from 'lodash-es'
import { api } from 'api'
import { UcoreDevice } from 'api/nca/types'
import { SelfData } from 'api/sso/profile'
import { UmrDevice } from 'api/umrDevices'
import {
  MAX_TRUNCATION_LENGTH,
  ORGANIZATION_ACCOUNT_TAG,
} from 'features/support/constants'
import { supportCategories } from './FallbackSupportModal'

const getUserName = (first_name?: string, last_name?: string) => {
  if (first_name && last_name) {
    return `${first_name} ${last_name}`
  }
  if (first_name) {
    return first_name
  }
  if (last_name) {
    return last_name
  }
  return ''
}

const hasAppInstalled = (app: string, controllers: any[]): boolean => {
  return controllers.some(
    (controller) => controller.name === app && controller.isInstalled
  )
}

const getDeviceInformation = (device: UcoreDevice) => {
  const mac = device.shadow.state.reported.mac
  const is_owner = device.owner
  const name = device.shadow.state.reported.hardware.name
  const user_given_name = device.shadow.state.reported.name
  const os_version = device.shadow.state.reported.version
  const shortname = device.shadow.state.reported.hardware.shortname
  const state = device.shadow.state.reported.state
  const id = device.id

  const { controllers } = device.shadow.state.reported
  const has_network_application = hasAppInstalled('network', controllers)
  const has_talk_application = hasAppInstalled('talk', controllers)
  const has_protect_application = hasAppInstalled('protect', controllers)
  const has_uid_application = hasAppInstalled('uid-agent', controllers)
  const has_connect_application = hasAppInstalled('connect', controllers)
  const has_access_application = hasAppInstalled('access', controllers)
  const has_drive_application = hasAppInstalled('drive', controllers)
  const has_talk_relay_application = hasAppInstalled('talk-relay', controllers)
  const has_innerspace_application = hasAppInstalled('innerspace', controllers)
  const tag = `uosfrm_cons_${shortname.toLowerCase()}`

  return {
    mac,
    is_owner,
    name,
    user_given_name,
    os_version,
    shortname,
    id,
    has_network_application,
    has_talk_application,
    has_protect_application,
    has_uid_application,
    has_connect_application,
    has_access_application,
    has_drive_application,
    has_talk_relay_application,
    has_innerspace_application,
    state,
    tag,
  }
}

export const getMetaFields = (
  ucoreDevices: UcoreDevice[],
  umrDevices: UmrDevice[],
  profileData: SelfData,
  isOrgAccount: boolean,
  country: string
) => {
  const { username, email, first_name, last_name, uuid } = profileData
  const name = getUserName(first_name, last_name)
  const external_id = uuid

  const console_quantity = ucoreDevices.length
  const consoles = JSON.stringify(
    ucoreDevices.map((device) => getDeviceInformation(device))
  )
  const latestRegisteredTimeStampDevice = maxBy(
    ucoreDevices,
    (device) => device.registrationTimestamp
  )
  const latest_registered_device = latestRegisteredTimeStampDevice
    ? `${latestRegisteredTimeStampDevice.shadow.state.reported.hardware.name} (${latestRegisteredTimeStampDevice.shadow.state.reported.name})`
    : ''

  return {
    username: username ?? '',
    email: email ?? '',
    name,
    external_id,
    console_quantity,
    consoles,
    latest_registered_device,
    umr_consoles: JSON.stringify(umrDevices),
    user_ip_country: country,
    extra_tags: isOrgAccount
      ? JSON.stringify([ORGANIZATION_ACCOUNT_TAG])
      : JSON.stringify([]),
  }
}

export const getSupportFileFromId = async (id: string) => {
  try {
    return await api.createSupportFile(id)
  } catch (error) {
    console.error(error)

    return Promise.resolve({ downloadUrl: 'Unknown' })
  }
}

export const getUmrSupportFileFromId = async (id: string) => {
  try {
    const result = await api.createUmrSupportFile(id)
    return result
  } catch (error) {
    console.error(error)
    return Promise.resolve({ download_url: 'Unknown' })
  }
}

const parseDescription = (description: string) => {
  if (description.length <= MAX_TRUNCATION_LENGTH) {
    return description
  }

  const truncated = description.slice(0, MAX_TRUNCATION_LENGTH)
  const lastSpaceIndex = truncated.lastIndexOf(' ')

  return lastSpaceIndex > -1 ? truncated.slice(0, lastSpaceIndex) : truncated
}

export const createFallbackSubject = (form: string, description: string) => {
  const supportCategory = supportCategories.find(
    ({ value }) => value === form
  )?.label
  const parsedDescription = parseDescription(description)

  const parsedCategory = supportCategory ? `${supportCategory}: ` : ''

  return `${parsedCategory}${parsedDescription}...`
}

import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import TextArea from '@ubnt/ui-components/TextArea/TextArea'
import { useFormik } from 'formik'
import { api } from 'api'
import { useQueryClient } from '@tanstack/react-query'
import { Dropdown } from '@ubnt/ui-components/Dropdown'
import { ToastContext } from '@ubnt/ui-components/Toast'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { FlexWrapper } from 'components/SharedComponents'
import EntityToast from 'components/EntityToast'
import { getRequester } from 'features/support/unifi/utils/requestUtils'
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  FALLBACK_SUPPORT_TAG,
  ORGANIZATION_ACCOUNT_TAG,
} from 'features/support/constants'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'
import { useZendeskUserQuery } from 'store/queries/useZendeskUserQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { QueryKey } from 'store/types'
import Yup from 'validators/yupLocaleConfig'
import { createFallbackSubject } from './utils'

export const supportCategories = [
  {
    label: 'UniFi Technical Support',
    value: 'unifi_technical_support',
  },
  {
    label: 'UISP Support',
    value: 'uisp_support',
  },
  {
    label: 'Store Support',
    value: 'store_support',
  },
  {
    label: 'UniFi Subscription Support',
    value: 'unifi_subscription_support',
  },
  {
    label: 'Mobility Technical Support',
    value: 'mobility_technical_support',
  },
]

const FallbackSupportModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { hasZendeskUser } = useZendeskUserQuery()
  const { profileData } = useSsoProfileQuery()
  const { accountType } = useOrganizationsQuery()
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const toast = useContext(ToastContext)

  const { ZENDESK_FALLBACK_FORM_KEY } = __CONFIG__

  const {
    handleSubmit,
    errors,
    values,
    isSubmitting,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik<{
    form: string
    description: string
  }>({
    initialValues: {
      form: '',
      description: '',
    },
    validationSchema: Yup.object({
      form: Yup.string().required().label('Form'),
      description: Yup.string()
        .min(DESCRIPTION_MIN_LENGTH)
        .max(DESCRIPTION_MAX_LENGTH)
        .required()
        .label('Description'),
    }),
    onSubmit: async (values) => {
      try {
        if (!profileData) return
        const requester = getRequester(profileData)

        const tags = [FALLBACK_SUPPORT_TAG]

        if (accountType === AccountType.ORG_OWNER) {
          tags.push(ORGANIZATION_ACCOUNT_TAG)
        }

        const formattedRequest = {
          ticket_form_id: ZENDESK_FALLBACK_FORM_KEY,
          requester,
          tags,
          subject: createFallbackSubject(values.form, values.description),
          comment: {
            body: values.description,
          },
          custom_fields: [
            {
              id: 31131569689239,
              value: values.form ?? '',
            },
          ],
        }

        await api.createZendeskRequest(formattedRequest)
        if (!hasZendeskUser) {
          queryClient.invalidateQueries({
            queryKey: [QueryKey.ZENDESK_USER],
          })
        }
        refetchOpenTickets()
      } catch (error) {
        toast.createNotification(
          <EntityToast
            icon="danger"
            title={
              <FormattedMessage id="SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION" />
            }
            onClose={(_e, id) => {
              if (id) {
                toast.removeNotification(id)
              }
            }}
          />
        )
      }
    },
  })

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_SIDE_NAV_SUPPORT_FORM" />}
      size="small"
      onRequestClose={() => {
        onClose?.()
      }}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: () => {
            onClose?.()
          },
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="SUPPORT_FOOTER_SUBMIT" />,
          variant: 'primary',
          type: 'submit',
          onClick: () => handleSubmit(),
          disabled: isSubmitting || Object.keys(errors).length > 0,
          loader: isSubmitting ? 'spinner' : undefined,
        },
      ]}
    >
      <FlexWrapper column gap={8}>
        <Dropdown
          options={supportCategories}
          value={values.form}
          onChange={({ value }) => setFieldValue('form', value)}
          variant="secondary"
          placeholder={intl.formatMessage({ id: 'REQUESTS_SUBJECT_FALLBACK' })}
        />
        <TextArea
          value={values.description}
          onChange={(e) => setFieldValue('description', e.target.value)}
          width="100%"
          maxWidth="100%"
          height="120px"
          variant="secondary"
          placeholder={intl.formatMessage({
            id: 'SUPPORT_DESCRIPTION_PLACEHOLDER',
          })}
          invalid={touched.description && errors.description}
          onBlur={handleBlur}
        />
      </FlexWrapper>
    </GenericModal>
  )
}

export const FALLBACK_SUPPORT_MODAL_ID = 'FALLBACK_SUPPORT_MODAL_ID'

export const WrappedFallbackSupportModal: React.FC = () => (
  <ModalWrapper modalId={FALLBACK_SUPPORT_MODAL_ID}>
    <FallbackSupportModal />
  </ModalWrapper>
)

import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AdaApi } from 'api/ada'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from '../types'

export const useUserScoreQuery = (api: AdaApi = new AdaApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const userScore = useQuery({
    queryKey: [QueryKey.ADA_SCORE],
    queryFn: api.get,
    enabled: isDoneAuthenticating,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    userScore: userScore.data?.score,
    isUserScoreLoading: userScore.isLoading,
  }
}

import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { TicketsApi } from 'api/zendesk/tickets'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { QueryKey } from 'store/types'
import { useZendeskUserQuery } from './useZendeskUserQuery'

export const useCCdTicketsQuery = (api: TicketsApi = new TicketsApi()) => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const { hasZendeskUser } = useZendeskUserQuery()

  const ccdTicketsResponse = useQuery({
    queryKey: [QueryKey.CCD_TICKETS],
    queryFn: api.getAllCcd,
    enabled: isDoneAuthenticating && hasZendeskUser,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  const ccdTickets = useMemo(
    () => ccdTicketsResponse.data?.tickets ?? [],
    [ccdTicketsResponse.data]
  )

  const totalCcdTickets = useMemo(
    () => ccdTicketsResponse.data?.totalTickets ?? 0,
    [ccdTicketsResponse.data?.totalTickets]
  )

  return {
    ccdTickets: ccdTickets,
    areCcdTicketsFetched: ccdTicketsResponse.isFetched,
    areCcdTicketsLoading: ccdTicketsResponse.isFetching,
    ccdTicketsError: ccdTicketsResponse.error,
    refetchCcdTickets: ccdTicketsResponse.refetch,
    totalCcdTickets,
  }
}

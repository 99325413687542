import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { Checkbox_Default } from '@ubnt/ui-components/aria/experimental'
import { FormattedMessage } from 'react-intl'
import QuoteIcon from '@ubnt/icons/QuoteIcon'
import { ToastContext, EntityToast } from '@ubnt/ui-components/Toast'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { getSubscriptionProductLine } from 'features/subscriptions/fullSubscriptions/utils'
import { useCancelSubscription } from 'pages/subscriptions/hooks/useCancelSubscription'
import styled from 'theme/styled'
import { ProductName } from 'types/enums'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
  productName?: ProductName
}

const CancelSubscriptionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
  productName,
}) => {
  const toast = useContext(ToastContext)

  const isCyberSecure =
    getSubscriptionProductLine(productName) === ProductName.CYBERSECURE

  const [cancelImmediately, setCancelImmediately] = useState(
    isCyberSecure && fullSubscription?.cancelAtPeriodEnd
  )

  const data = useCancelSubscription(
    productName,
    fullSubscription,
    cancelImmediately
  )

  useEffect(() => {
    if (data?.cancelError) {
      toast.createNotification(
        <EntityToast
          icon={<QuoteIcon />}
          stateIndicator="danger"
          title={
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_ERROR" />
          }
          duration={5000}
          details={
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          }
          onClose={(_e: React.MouseEvent, id?: string) => {
            if (id) {
              toast.removeNotification(id)
            }
          }}
        />
      )
    }
  }, [data?.cancelError, toast])

  const modalContent = useMemo(() => {
    if (data?.cancelError) {
      return <Text color="red-06">{data.cancelError}</Text>
    }

    const isCyberSecureCanceledAtPeriodEnd =
      isCyberSecure && fullSubscription?.cancelAtPeriodEnd

    if (isCyberSecure) {
      return (
        <ModalContentWrapper>
          <Text>
            <FormattedMessage
              id={
                isCyberSecureCanceledAtPeriodEnd
                  ? 'SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_IMMEDIATELY'
                  : 'SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_PERIOD_END'
              }
              values={{ name: fullSubscription?.name }}
            />
          </Text>
          <StyledCheckbox
            isSelected={cancelImmediately}
            isDisabled={isCyberSecureCanceledAtPeriodEnd}
            onChange={() => setCancelImmediately(!cancelImmediately)}
            label={
              <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_CYBERSECURE_IMMEDIATELY_WITH_WARNING" />
            }
          />
        </ModalContentWrapper>
      )
    }
    return (
      <Text>
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
          values={{ name: fullSubscription?.name }}
        />
      </Text>
    )
  }, [
    cancelImmediately,
    data?.cancelError,
    fullSubscription?.cancelAtPeriodEnd,
    fullSubscription?.name,
    isCyberSecure,
  ])

  if (!data) {
    return null
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION" />
      }
      size="small"
      onAfterClose={() => data.resetCancelErrors()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_CONFIRM" />,
          variant: 'primary',
          onClick: () => data.cancelFn(),
          loader: data.isCancelLoading ? 'dots' : undefined,
          type: 'submit',
          disabled: data.isCancelLoading,
        },
      ]}
    >
      {modalContent}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_MODAL_ID = 'CANCEL_SUBSCRIPTION_MODAL_ID'

export const WrappedCancelSubscriptionModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_MODAL_ID}>
    <CancelSubscriptionModal />
  </ModalWrapper>
)

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledCheckbox = styled(Checkbox_Default)`
  width: auto;

  & > div:first-child {
    width: auto;
  }
`

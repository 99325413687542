import { useQuery } from '@tanstack/react-query'
import { GeoApi } from 'api/geo'
import { QueryKey } from '../types'

export const useGeoLocationQuery = (api: GeoApi = new GeoApi()) => {
  const locationInfo = useQuery({
    queryKey: [QueryKey.GEO_LOCATION],
    queryFn: api.get,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })

  return {
    locationInfo: locationInfo.data,
    isLocationInfoLoading: locationInfo.isLoading,
  }
}

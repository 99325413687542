import React, { useContext } from 'react'
import { Global, css } from '@emotion/react'
import { connect } from 'react-redux'
import '@ubnt/webfonts/ui-sans-400.css'
import '@ubnt/webfonts/ui-sans-700.css'
import '@ubnt/webfonts/ui-sans-900.css'
import theme, { motifPalette } from 'theme'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { MotifContext } from 'motif/MotifProvider'
import { RootState } from 'types/types'

interface Props {
  isAuthenticated: boolean
}

const GlobalStyles: React.FC<Props> = ({ isAuthenticated }) => {
  const { motif } = useContext(MotifContext)

  const globalStyles = css`
    body,
    html {
      box-sizing: border-box;
      background: ${motifPalette[motif].neutral00};

      font-synthesis: none;
      font-feature-settings: 'kern';
      -moz-font-feature-settings: 'kern';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      margin: 0;
      padding: 0;

      #ada-chat-frame {
        bottom: 52px !important;
        z-index: 1000 !important;
        border-radius: 16px 16px 0 0 !important;

        @media (max-width: 500px) {
          bottom: 48px !important;
          height: calc(100% - 48px) !important;
        }
      }

      #secure-attachments-button {
        width: 100%;
        max-width: 375px;
        height: 48px;
        padding: 16px 0;
        background: #006fff;
        position: fixed;
        bottom: 8px;
        right: 24px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 12px 48px 4px;
        border-radius: 0 0 16px 16px;
        z-index: 1000;
        transform: translate(0px, 0px);
        transition:
          transform 300ms,
          opacity 300ms;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }

        &.full-screen {
          width: 100%;
          max-width: 100%;
          right: 0;
          bottom: 0;
          border-radius: 0;
        }
      }

      #secure-attachments-button.hidden {
        visibility: hidden;
      }

      #sendsafely-dropzone {
        img {
          display: none;
        }
      }

      #sendsafely-attached-file-list {
        img {
          width: 20px;
          height: 20px;
        }

        span[data-upload-percentage] {
          display: none;
        }

        div[data-upload-progress] {
          display: none;
        }
      }

      #secure-attachments-modal {
        display: flex;
        flex-direction: column;
        align-items: felx-start;
        justify-content: center;
        height: 100%;
        width: 100%;
        gap: 8px;
      }

      #secure-attachments-modal.hidden {
        visibility: hidden;
      }

      .grecaptcha-badge {
        visibility: hidden;
      }

      .paymentsPopoverCard {
        width: 111px;
        height: 64px;
        padding: 4px 0;
      }

      .override-index {
        z-index: 5;
      }

      .change-region__dropdown {
        box-shadow:
          0px 4px 12px ${motifPalette[motif].neutral02},
          0px 0px 1px ${motifPalette[motif].neutral02};
      }

      .intl-message {
        padding-left: 2px;
      }

      .sortPopover-wrapper {
        width: 207px;
        padding: 0;
        div:first-of-type {
          width: 207px;
          padding: 0;
        }
      }

      .payments-popover__card {
        padding: 4px 0;
        width: 32px;
      }

      .subscription-services-popover__card {
        padding: 4px 0;
      }

      .customTooltipWidth {
        width: 240px !important;
      }

      .customSubscriptionsTooltipWidth {
        width: 280px !important;
      }

      @media (max-width: ${theme.media.small}) {
        &.mobile-open {
          overflow: hidden;
        }
      }
      @media (max-width: ${theme.media.mobileMedium}) {
        .cardWrapper {
          div {
            margin-left: 10px;
            width: calc(100vw - 50px);
          }
        }
      }
    }

    body,
    html,
    button {
      font-family: 'UI Sans', sans-serif;
    }

    html,
    body,
    #root {
      position: relative;
      min-height: 100vh;
      ${isAuthenticated ? 'height: 100%' : ''}
    }
    @media print {
      #root,
      html,
      body {
        height: 98%;
      }
    }
    img {
      image-rendering: -moz-crisp-edges; // Firefox
      image-rendering: -o-crisp-edges; //Opera
      image-rendering: -webkit-optimize-contrast; // Webkit
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; // IE
    }
    .hidden {
      display: none !important;
    }
  `
  return <Global styles={globalStyles} />
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: selectIsAuthenticated(state),
})

export default connect(mapStateToProps)(React.memo(GlobalStyles))

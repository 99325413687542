import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { useHistory } from 'react-router-dom'
import {
  AccountType,
  useOrganizationsQuery,
} from 'store/queries/useOrganizationsQuery'
import styled from 'theme/styled'
import { OrganizationManagerDropdown } from './OrganizationManagerDropdown'
import { getOrgImage } from './utils'

export const OrganizationManager = () => {
  const { accountType, organizations, isOwnerAndAdmin } =
    useOrganizationsQuery()
  const history = useHistory()

  if (accountType === AccountType.PERSONAL_ACCOUNT) {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.get('org')) {
      queryParams.delete('org')
      history.replace({
        search: queryParams.toString(),
      })
    }
    return null
  }

  if (
    accountType === AccountType.ORG_OWNER &&
    organizations &&
    !isOwnerAndAdmin
  ) {
    return (
      <OrgOwnerWrapper>
        {getOrgImage(organizations[0].logoUrl)}
        <Text color="text-1">{organizations[0].name}</Text>
      </OrgOwnerWrapper>
    )
  }

  return <OrganizationManagerDropdown />
}

const OrgOwnerWrapper = styled.div`
  padding: 8px;
  margin: 0 16px 16px 16px;
  display: flex;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.neutral03};
  border-radius: 4px;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

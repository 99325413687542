import { fetchRequest } from './axios'

const {
  api: { geo },
} = __CONFIG__

interface LocationInfo {
  continent_code: string
  country_code: string
  country_name: string
  timezone: string
}

export class GeoApi {
  public get = async () => {
    const url = new URL(geo.paths.geo, geo.base)
    const { data } = await fetchRequest<LocationInfo>(url.href)
    return data
  }
}

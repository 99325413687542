import { InfiniteData } from '@tanstack/react-query'
import { last, sum, unionBy } from 'lodash-es'
import { DateTime } from 'luxon'
import { StripeStatus } from 'sharedConstants'
import type Stripe from 'stripe'
import { UcoreDevice } from 'api/nca/types'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { getSubscriptionProductLine } from 'features/subscriptions/fullSubscriptions/utils'
import {
  InvoiceData,
  Invoices,
  StripeInvoice,
} from 'features/subscriptions/module/types'
import { getProductIdFromInvoice } from 'features/subscriptions/utils'
import { ProductName } from 'types/enums'
import {
  getBilledCard,
  getDate,
  getDeviceName,
} from '../components/invoices/InvoicesTable/utils'
import { ParsedInvoice } from '../components/invoices/types'

export interface InvoiceItem {
  monthLabel: string
  id: string
  month: string
  year: string
  total: number
  children: StripeInvoice[]
  comperator?: number
  totalUnpaid: number
}

interface PaginationInfo {
  region: StripeRegionCode
  hasMore: boolean
  startingAfter: string
}

export type InvoiceYearInfo = {
  paginationInfo: PaginationInfo[] | null
  invoices: StripeInvoice[]
  invoiceTableItems: InvoiceItem[]
  shouldInvoicesPaginate: boolean
}

export type InvoiceYearsInfo = Record<string, InvoiceYearInfo>

const INVOICES_DEFAULTS = {
  data: [],
  meta: { hasMore: false, startingAfter: '' },
}

export const mergeInvoices = (
  invoiceData?: InvoiceData,
  originalData?: Invoices
) => {
  const mergedData: Invoices = originalData ?? {
    us: INVOICES_DEFAULTS,
    ca: INVOICES_DEFAULTS,
    gb: INVOICES_DEFAULTS,
    eu: INVOICES_DEFAULTS,
    au: INVOICES_DEFAULTS,
    ae: INVOICES_DEFAULTS,
    sg: INVOICES_DEFAULTS,
    row: INVOICES_DEFAULTS,
    jp: INVOICES_DEFAULTS,
    mx: INVOICES_DEFAULTS,
    br: INVOICES_DEFAULTS,
  }

  const regions = Object.keys(mergedData) as StripeRegionCode[]

  regions.forEach((region) => {
    if (invoiceData?.[region]) {
      const regionalData = invoiceData[region].data
      const mergedRegionalData = mergedData[region].data
      const updatedData = unionBy(mergedRegionalData, regionalData, 'id')
      const hasMore = invoiceData[region].has_more

      mergedData[region as StripeRegionCode] = {
        data: updatedData,
        meta: {
          hasMore,
          startingAfter: hasMore
            ? regionalData[regionalData.length - 1].id
            : '',
        },
      }
    }
  })

  return mergedData
}

export const mergeInvoicePages = (
  queryData?: InfiniteData<InvoiceData, unknown>
) => {
  const mergedData: Invoices = {
    us: INVOICES_DEFAULTS,
    ca: INVOICES_DEFAULTS,
    gb: INVOICES_DEFAULTS,
    eu: INVOICES_DEFAULTS,
    au: INVOICES_DEFAULTS,
    ae: INVOICES_DEFAULTS,
    sg: INVOICES_DEFAULTS,
    row: INVOICES_DEFAULTS,
    jp: INVOICES_DEFAULTS,
    mx: INVOICES_DEFAULTS,
    br: INVOICES_DEFAULTS,
  }

  if (!queryData) return null
  const { pages } = queryData

  return pages.reduce((result, page) => {
    return mergeInvoices(page, result)
  }, mergedData)
}

const sortInvoices = (invoices: StripeInvoice[]) => {
  return invoices.sort((a, b) => {
    const billingDateA = a.status_transitions.finalized_at ?? 0
    const billingDateB = b.status_transitions.finalized_at ?? 0
    return billingDateB - billingDateA
  })
}

export const getSortedInvoices = (
  invoices: Invoices | null
): StripeInvoice[] => {
  if (!invoices) return []
  const invoiceRegions = Object.keys(invoices) as StripeRegionCode[]

  const userInvoices = invoiceRegions.flatMap((region) => {
    return (
      invoices[region].data?.map((invoice) => {
        return { ...invoice, region }
      }) ?? []
    )
  })
  return sortInvoices(userInvoices)
}

export const getPaginationInfo = (
  invoices: Invoices | null
): PaginationInfo[] => {
  if (!invoices) return []
  return Object.entries(invoices).map(([key, value]) => ({
    region: key as StripeRegionCode,
    ...value.meta,
  }))
}

export const getInvoice = (invoiceId: string, invoices: StripeInvoice[]) =>
  invoices.find(({ id }) => id === invoiceId)

export const parseInvoices = (invoices: StripeInvoice[]) => {
  const filteredInvoices = invoices.filter((invoice) => {
    if (invoice.metadata?.ui_product_line === ProductName.UNIFI_ACCESS) {
      // UniFi Access does not have a subscription, therefore no plan, and these we do not want
      // to filter out
      return true
    }
    return last(invoice.lines.data)?.plan
  })
  const itemsMap = filteredInvoices.reduce<{ [key: string]: InvoiceItem }>(
    (agg, invoice) => {
      const finalizedTs = DateTime.fromSeconds(
        invoice.status_transitions.finalized_at ?? Date.now()
      )

      const key = finalizedTs.toFormat('M.yyyy')
      if (!agg[key]) {
        agg[key] = {
          id: key,
          monthLabel: `${finalizedTs.toFormat('MMMM')}, ${finalizedTs.toFormat(
            'yyyy'
          )}`,
          month: finalizedTs.toFormat('MMMM'),
          year: finalizedTs.toFormat('yyyy'),
          total: invoice.status === 'void' ? 0 : invoice.total,
          children: [invoice],
          totalUnpaid: invoice.status === StripeStatus.OPEN ? 1 : 0,
        }
        return agg
      }

      const item = agg[key]
      if (invoice.status !== 'void') {
        item.total += invoice.total
        if (invoice.status === StripeStatus.OPEN) {
          item.totalUnpaid += 1
        }
      }
      item.children.push(invoice)

      return agg
    },
    {}
  )

  return Object.keys(itemsMap).map((key) => {
    itemsMap[key].comperator = sum(key.split('.').map((n) => parseInt(n, 10)))
    return itemsMap[key]
  })
}

export const parseTableInvoice = (
  invoice: StripeInvoice,
  getProduct: (id: string) => Stripe.Product | undefined,
  ownedUcoreDevices: UcoreDevice[]
): ParsedInvoice => {
  const productId = getProductIdFromInvoice(invoice)

  const product = getProduct(productId)

  const metadataName =
    invoice.metadata?.ui_product_line ||
    invoice.lines?.data?.[0]?.metadata.ui_product_line

  const subscriptionName = getSubscriptionProductLine(
    product?.name,
    metadataName
  )

  const date = getDate(invoice.status_transitions.finalized_at)

  const metadataProduct =
    invoice.metadata?.ui_product_line ||
    invoice.lines?.data?.[0]?.metadata.ui_product_line
  const productLine = getSubscriptionProductLine(product?.name, metadataProduct)

  const name = getDeviceName(invoice, ownedUcoreDevices, productLine)

  const billedCard = getBilledCard(invoice)

  const { total, currency, id, status } = invoice

  return {
    name,
    date,
    subscriptionName,
    billedCard,
    status: status as StripeStatus,
    total,
    currency,
    id,
  }
}

import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import {
  BLACKLISTED_IP_INFO_MODAL,
  WrappedBlacklistedIpInfoModal,
} from 'components/BlacklistedIpInfoModal'
import { sessionStorageChatKey } from 'features/support/unifi/UniFiForm'
import { setVisibleModal } from 'modules/modals'
import { ProtectedPage } from 'pages/ProtectedPage'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'
import { useZendeskUserQuery } from 'store/queries/useZendeskUserQuery'
import { QueryKey } from 'store/types'
import { useGeoLocationQuery } from 'store/queries/useGeoLocationQuery'
import styled from 'theme/styled'
import { isIpBlacklisted } from 'utils/redirectIfIpIsBlacklisted'
import { withScoreCheck } from 'utils/withScoreCheck'
import { OldRequestsContainer, RequestsContainer } from './RequestsContainer'

const OldRequests: React.FC = () => {
  const dispatch = useDispatch()

  const [isCountryBlacklisted, setIsCountryBlacklisted] = useState<
    boolean | null
  >(null)
  const sessionStorageChatInfo = sessionStorage.getItem(sessionStorageChatKey)
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const queryClient = useQueryClient()
  const { hasZendeskUser } = useZendeskUserQuery()
  const { locationInfo } = useGeoLocationQuery()

  useEffect(() => {
    if (locationInfo) {
      const { isBlacklisted } = isIpBlacklisted(locationInfo.country_name)
      if (isBlacklisted) {
        setIsCountryBlacklisted(true)
        dispatch(
          setVisibleModal(BLACKLISTED_IP_INFO_MODAL, {
            country: locationInfo.country_name,
          })
        )
      }
    }
  }, [dispatch, locationInfo])

  useEffect(() => {
    // Temporarily needed because the app does not know the agent closed the chat, and therefore tickets must refetch
    // unless the user closes the chatWidget, so if the widget is still open we should always refetch when this page is visited
    // in case the agent has already closed the chat
    if (!hasZendeskUser && sessionStorageChatInfo === 'true') {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ZENDESK_USER],
      })
    }
    if (sessionStorageChatInfo === 'true') {
      refetchOpenTickets()
    }
  }, [hasZendeskUser, queryClient, refetchOpenTickets, sessionStorageChatInfo])

  return (
    <ProtectedPage>
      <Container>
        {isCountryBlacklisted ? null : <OldRequestsContainer />}
      </Container>
      <WrappedBlacklistedIpInfoModal />
    </ProtectedPage>
  )
}

const Requests: React.FC = () => {
  const dispatch = useDispatch()
  const [isCountryBlacklisted, setIsCountryBlacklisted] = useState<
    boolean | null
  >(null)
  const { locationInfo } = useGeoLocationQuery()

  useEffect(() => {
    if (locationInfo) {
      const { isBlacklisted } = isIpBlacklisted(locationInfo.country_name)
      if (isBlacklisted) {
        setIsCountryBlacklisted(true)
        dispatch(
          setVisibleModal(BLACKLISTED_IP_INFO_MODAL, {
            country: locationInfo.country_name,
          })
        )
      }
    }
  }, [dispatch, locationInfo])

  return (
    <ProtectedPage>
      <Container>
        {isCountryBlacklisted ? null : <RequestsContainer />}
      </Container>
      <WrappedBlacklistedIpInfoModal />
    </ProtectedPage>
  )
}

export default withScoreCheck(OldRequests, Requests)

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
`

import React from 'react'
import { Button } from '@ubnt/ui-components/Button'
import { FormattedMessage } from 'react-intl'
import {
  EmptyStateContainer,
  EmptyStateContent,
  StyledExclamationMarkIcon,
  WarningIconContainer,
} from './Backups.styles'

export const BackupsErrorPlaceholder = () => {
  return (
    <EmptyStateContainer>
      <WarningIconContainer>
        <StyledExclamationMarkIcon variant="fill" />
      </WarningIconContainer>

      <EmptyStateContent>
        <FormattedMessage id="SETTINGS_BACKUPS_FETCH_ERROR" />
      </EmptyStateContent>

      <Button variant="secondary" onClick={() => window.location.reload()}>
        <FormattedMessage id="COMMON_ACTION_REFRESH" />
      </Button>
    </EmptyStateContainer>
  )
}

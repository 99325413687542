import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeProvider } from '@emotion/react'
import { ToastProvider } from '@ubnt/ui-components'
import { createBrowserHistory } from 'history'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Router,
  Switch,
} from 'react-router-dom'
import { compose } from 'redux'
import theme, { motifPalette } from 'theme'
import { ErrorPageFourOhFour } from 'core/error-handling/ErrorPages'
import {
  checkAuth,
  checkAuthCookie,
  selectIsDoneAuthenticating,
} from 'features/auth/modules/auth'
import ChangePasswordForm from 'features/auth/ui/ChangePasswordForm'
import { ChangeEmail } from 'features/auth/ui/emailChange/ChangeEmail'
import { ChangeEmailSuccess } from 'features/auth/ui/emailChange/ChangeEmailSuccess'
import { ConfirmEmailChange } from 'features/auth/ui/emailChange/ConfirmEmailChange'
import ForgotPasswordForm from 'features/auth/ui/ForgotPasswordForm'
import LoginForm from 'features/auth/ui/LoginForm'
import Logout from 'features/auth/ui/Logout'
import { MfaFeedback } from 'features/auth/ui/mfaFeedback/MfaFeedback'
import { MfaFeedbackSuccess } from 'features/auth/ui/mfaFeedback/MfaFeedbackSuccess'
import { RegisterForm } from 'features/auth/ui/registerAccount/RegisterForm'
import RegisterSuccess from 'features/auth/ui/RegisterSuccess'
import { TrustDevice } from 'features/auth/ui/TrustDevice'
import VerifyEmail from 'features/auth/ui/VerifyEmail'
import { fetchRoles } from 'features/early-access/module/roles'
import {
  innerRedirect,
  outerRedirect,
  storedTrustDeviceRedirectKey,
  trustDeviceRedirect,
  useOuterRedirectStore,
} from 'features/redirect-after-register'
import LoginMFA from 'features/auth/ui/LoginMFA'
import SetMFAFirstLogin from 'features/auth/ui/SetMFAFirstLogin'
import { ResendVerificationComplete } from 'features/auth/ui/ResendVerificationFormComplete'
import { RedirectPage } from 'features/auth/ui/RedirectPage'
import { MaintenancePage } from 'features/status/ui/MaintenancePage'
import { StripeRegionProvider } from 'features/stripe/ui/Region'
import WithAda from 'features/ada/useAda'
import { QuickSupportPage } from 'features/support/support-quick-link/QuickSupportPage'
import { useMotif } from 'motif/useMotif'
import { useStatusQuery } from 'store/queries/useStatusQuery'
import { usePrefetchQueries } from 'store/usePrefetchQueries'
import { useTracking } from 'utils/useTracking'
import Backups from './backups/Backups'
import Home from './home/Home'
import { Payments } from './payments/Payments'
import { Validate3DS } from './payments/Validate3DS'
import Profile from './profile/Profile'
import { AlertSlackChannel } from './request/AlertSlackChannel'
import { Request } from './request/Request'
import Requests from './requests/Requests'
import { RequestsProvider } from './requests/RequestsContext'
import Security from './security/Security'
import { InvoiceProvider } from './subscriptions/components/InvoiceContext'
import { UniFiChat } from './support/chat/UniFiChat'
import { Subscriptions } from './subscriptions/Subscriptions'
import { SubscriptionService } from './subscriptions/SubscriptionService'
import { ChatContext } from './support/chat/ChatContext'
import SupportFormPage from './supportForm/SupportFormPage'

const history = createBrowserHistory()

const {
  api: { activate },
} = __CONFIG__

const App = () => {
  useTracking(__CONFIG__.GOOGLE_ANALYTICS_KEY)
  useOuterRedirectStore()
  const dispatch = useDispatch()
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const { widgetState } = useContext(ChatContext)

  const { activeMaintenance } = useStatusQuery()

  const [isRedirecting, setIsRedirecting] = useState(false)
  const hasTrustDeviceRedirect = !!localStorage?.getItem(
    storedTrustDeviceRedirectKey
  )

  const FETCH_USER_INTERVAL = 15000

  const motif = useMotif()
  usePrefetchQueries()

  useEffect(() => {
    if (isDoneAuthenticating) {
      const interval = setInterval(async () => {
        await checkAuthCookie()
      }, FETCH_USER_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [isDoneAuthenticating])

  useEffect(() => {
    dispatch(checkAuth())
    dispatch(fetchRoles())
  }, [dispatch])

  const checkTrustDeviceRedirect = useCallback(() => {
    if (!isDoneAuthenticating) return false
    trustDeviceRedirect(history)
  }, [isDoneAuthenticating])

  const checkOuterRedirect = useCallback(() => {
    // Hacky solution to intercept the original redirect logic in other to be able to display the trust device page that follows an mfa login
    if (!isDoneAuthenticating || hasTrustDeviceRedirect) return false
    if (!isRedirecting && !outerRedirect()) return false
    if (!isRedirecting) {
      setIsRedirecting(true)
    }
    return <Route component={RedirectPage} />
  }, [isDoneAuthenticating, hasTrustDeviceRedirect, isRedirecting])

  const checkInnerRedirect = useCallback(() => {
    // Hacky solution to intercept the original redirect logic in other to be able to display the trust device page that follows an mfa login
    if (!isDoneAuthenticating || hasTrustDeviceRedirect) return false
    return innerRedirect(history)
  }, [isDoneAuthenticating, hasTrustDeviceRedirect])

  if (activeMaintenance) return <MaintenancePage />

  return (
    <ThemeProvider theme={{ ...theme, ...motifPalette[motif] }}>
      <WithAda />
      {/* @ts-ignore */}
      <Router history={history}>
        {widgetState.isInitialised && <UniFiChat />}
        <ToastProvider fixed>
          <StripeRegionProvider>
            <Switch>
              {checkTrustDeviceRedirect()}
              {checkOuterRedirect()}
              {checkInnerRedirect()}
              <Route
                exact
                path="/reset-password/password-reset-uuid/:uuid"
                component={ChangePasswordForm}
              />
              <Route
                exact
                path="/reset-password"
                component={ForgotPasswordForm}
              />
              <Route
                exact
                path="/verify/verification-code/:uuid"
                component={VerifyEmail as any}
              />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/login" component={LoginForm} />
              <Route exact path="/login/mfa" component={LoginMFA} />
              <Route exact path="/login/trust-device" component={TrustDevice} />
              <Route
                exact
                path="/reset-2fa"
                component={() => <Redirect to="/login" />}
              />
              <Route exact path="/register" component={RegisterForm} />
              <Route
                exact
                path="/register/success"
                component={RegisterSuccess}
              />
              <Route
                exact
                path="/verify/success"
                component={SetMFAFirstLogin}
              />
              <Route
                exact
                path="/resend-verification"
                component={() => <Redirect to="/login" />}
              />
              <Route
                exact
                path="/resend-verification/success"
                component={ResendVerificationComplete}
              />
              <Route exact path="/" component={Home} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/security" component={Security} />
              <Route exact path="/payments" component={Payments} />
              <Route exact path="/subscriptions">
                <InvoiceProvider>
                  <Subscriptions />
                </InvoiceProvider>
              </Route>
              <Route exact path="/subscriptions/:service">
                <InvoiceProvider>
                  <SubscriptionService />
                </InvoiceProvider>
              </Route>
              <Route
                exact
                path="/invoices/:invoiceId"
                component={({
                  match: {
                    params: { invoiceId },
                  },
                }: RouteComponentProps<{ invoiceId: string }>) => {
                  return (
                    <Redirect to={`/subscriptions?invoiceId=${invoiceId}`} />
                  )
                }}
              />
              <Route exact path="/backups" component={Backups} />
              <Route
                exact
                path="/mysupport"
                component={() => <Redirect to="/requests" />}
              />
              <Route exact path="/supportform">
                <SupportFormPage />
              </Route>
              <Route exact path="/support/quick-link">
                <QuickSupportPage />
              </Route>
              <Route
                exact
                path="/supportform-uisp"
                component={() => <Redirect to="/supportform?formtype=uisp" />}
              />
              <Route
                exact
                path="/storeform"
                component={() => <Redirect to="/supportform?formtype=store" />}
              />
              <Route
                exact
                path="/supportform-umr"
                component={() => <Redirect to="/supportform?formtype=umr" />}
              />
              <Route exact path="/requests">
                <RequestsProvider>
                  <Requests />
                </RequestsProvider>
              </Route>
              <Route exact path="/requests/:ticketId">
                <RequestsProvider>
                  <Request />
                </RequestsProvider>
              </Route>
              <Route
                exact
                path="/activate"
                component={() => {
                  window.location.replace(activate.base)
                  return null
                }}
              />
              <Route
                exact
                path="/advanced"
                component={() => <Redirect to="/" />}
              />
              <Route
                exact
                path="/earlyaccess"
                component={() => <Redirect to="/profile" />}
              />
              <Route path="/manage" component={() => <Redirect to="/" />} />
              <Route
                exact
                path="/request/alert/:ticketId"
                component={AlertSlackChannel}
              />
              <Route exact path="/login/change-email" component={ChangeEmail} />
              <Route
                exact
                path="/login/change-email/confirm"
                component={ConfirmEmailChange}
              />
              <Route
                exact
                path="/login/change-email/success"
                component={ChangeEmailSuccess}
              />
              <Route exact path="/login/mfa-feedback" component={MfaFeedback} />
              <Route
                exact
                path="/login/mfa-feedback/success"
                component={MfaFeedbackSuccess}
              />
              <Route exact path="/payments/3ds" component={Validate3DS} />
              <Route component={ErrorPageFourOhFour} />
            </Switch>
          </StripeRegionProvider>
        </ToastProvider>
      </Router>
    </ThemeProvider>
  )
}

const AppConnected = compose<typeof App>()(App)

export default AppConnected

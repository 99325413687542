import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ZendeskUserApi } from 'api/zendesk/user'
import { QueryKey } from 'store/types'

export const useCreateOrUpdateZendeskUser = (
  api: ZendeskUserApi = new ZendeskUserApi()
) => {
  const queryClient = useQueryClient()

  const createOrUpdateZendeskUser = useMutation({
    mutationFn: api.createOrUpdate,
    onSuccess: (data) =>
      queryClient.setQueryData([QueryKey.ZENDESK_USER], data),
  })

  return {
    createOrUpdateZendeskUser: createOrUpdateZendeskUser.mutate,
  }
}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { motifPalette } from 'theme'
import { useMotif } from '@ubnt/ui-components/utils'
import { CopyLink } from '@ubnt/ui-components/CopyLink'
import { Text } from '@ubnt/ui-components/aria'
import FileIcon from 'assets/svgs/fileIcon.svg'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { FlexWrapper } from 'components/SharedComponents'
import { SSWidget } from 'features/support/unifi/types'
import observeDOM from 'features/support/observeDOM'
import { useSsoProfileQuery } from 'store/queries/useSsoProfileQuery'

const { SEND_SAFELY_DZ_ID, SEND_SAFELY_HOST } = __CONFIG__

const SecureAttachmentsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const { profileData } = useSsoProfileQuery()
  const [widget, setWidget] = useState<SSWidget | undefined>(undefined)
  const [isElementMounted, setIsElementMounted] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const { motif } = useMotif()
  const intl = useIntl()
  const { isMaxMobileLarge } = useContext(MediaSizingContext)
  const [fileUploadedUrl, setFileUploadedUrl] = useState<string | undefined>(
    undefined
  )
  const [hasUploads, setHasUploads] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      setIsElementMounted(false)
      return
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const element = document.getElementById('secure-attachments-modal')
          if (element) {
            setIsElementMounted(true)
            observer.disconnect()
          }
        }
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => {
      observer.disconnect()
    }
  }, [isOpen])

  const dropzonePlaceholderText = intl.formatMessage({
    id: isMaxMobileLarge
      ? 'SETTINGS_SUPPORT_DROPZONE_MOBILE'
      : 'SETTINGS_SUPPORT_DROPZONE',
  })

  useEffect(() => {
    if (isOpen && isElementMounted && !widget) {
      const element = document.getElementById('secure-attachments-modal')
      if (element) {
        const newWidget = new window.SendSafelyDropzone(
          SEND_SAFELY_DZ_ID,
          $(element)
        )

        newWidget.url = SEND_SAFELY_HOST
        newWidget.disableAutoSubmit = true
        newWidget.DROPZONE_TEXT = dropzonePlaceholderText
        newWidget.DROPZONE_TEXT_MOBILE = dropzonePlaceholderText
        newWidget.DROP_TEXT_COLOR = motifPalette[motif].text2
        newWidget.DROPZONE_STYLE = `font-size: 14px; line-height: 20px; text-align: center; padding: 18px; border-radius: 8px; border: 1px dashed ${motifPalette[motif].neutral03}; height: 56px; display: flex; align-items: center;`
        newWidget.PROGRESS_STYLE = ''
        newWidget.FILE_ROW_STYLE = `border: none; border-radius: 4px; font-size: 14px; line-height: 20px; margin: 0px; padding: 8px; overflow: hidden; background: ${motifPalette[motif].neutral01};`
        newWidget.logoPath = FileIcon
        newWidget.PREPARING_TO_UPLOAD_TEXT = 'Loading...'

        newWidget.initialize()

        setWidget(newWidget)
      }
    }

    return () => {
      if (widget) {
        setWidget(undefined)
      }
    }
  }, [isOpen, isElementMounted, widget, dropzonePlaceholderText, motif])

  const checkIfUploadingComplete = useCallback(() => {
    const placeholderElement = $('#secure-attachments-modal')
    const uploadedDocuments = placeholderElement.find('li')

    if (uploadedDocuments && uploadedDocuments.length > 0) {
      setIsUploading(true)

      const addedDocumentsArray = Array.from(uploadedDocuments)
      const uploadCompleted = addedDocumentsArray?.every((upload) =>
        upload?.innerHTML?.toLowerCase().includes('completed')
      )
      setIsUploading(!uploadCompleted)
      setHasUploads(true)
    } else {
      setIsUploading(false)
      setHasUploads(false)
    }
  }, [setIsUploading])

  useEffect(() => {
    if (widget) {
      observeDOM('secure-attachments-modal', checkIfUploadingComplete)
    }
  }, [checkIfUploadingComplete, widget])

  const finalizeSendSafelyUpload = useCallback(
    (cb: (url: string | undefined) => void, email: string | undefined) => {
      if (widget && widget.nbrOfFilesAttached > 0) {
        widget.setUnconfirmedSender(email)
        widget.finalizePackage(function (url) {
          cb(url)
        })
      } else {
        cb(undefined)
      }
    },
    [widget]
  )

  const uploadAttachments = () => {
    return new Promise((resolve, reject) => {
      try {
        finalizeSendSafelyUpload((url) => {
          resolve(url)
        }, profileData?.email)
      } catch (e) {
        reject('Problem uploading file.')
      }
    })
  }

  const handleSendSafelyUpload = async () => {
    try {
      const uploadedFileUrl = await uploadAttachments()
      if (typeof uploadedFileUrl === 'string') {
        setFileUploadedUrl(uploadedFileUrl)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="SECURE_ATTACHMENTS_MODAL_TITLE" />}
      size="small"
      onRequestClose={() => {
        onClose?.()
      }}
      actions={
        fileUploadedUrl
          ? []
          : [
              {
                text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
                onClick: () => {
                  onClose?.()
                },
                variant: 'tertiary',
              },
              {
                text: <FormattedMessage id="COMMON_ACTION_UPLOAD" />,
                variant: 'primary',
                type: 'submit',
                onClick: handleSendSafelyUpload,
                disabled: isUploading || !hasUploads,
              },
            ]
      }
    >
      <div
        id="secure-attachments-modal"
        className={fileUploadedUrl ? 'hidden' : ''}
      />
      {fileUploadedUrl && (
        <FlexWrapper column gap={8}>
          <Text>
            {intl.formatMessage({
              id: 'SECURE_ATTACHMENTS_MODAL_DESCRIPTION',
            })}
          </Text>
          <CopyLink link={fileUploadedUrl} />
        </FlexWrapper>
      )}
    </GenericModal>
  )
}

export const SECURE_ATTACHMENTS_MODAL_ID = 'SECURE_ATTACHMENTS_MODAL_ID'

export const WrappedSecureAttachmentsModal: React.FC = () => (
  <ModalWrapper modalId={SECURE_ATTACHMENTS_MODAL_ID}>
    <SecureAttachmentsModal />
  </ModalWrapper>
)

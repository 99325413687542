import React from 'react'
import { useUserScoreQuery } from 'store/queries/useUserScore'

const MAX_SCORE_TO_OPEN_ADA = 100

export const withScoreCheck = (
  OldComponent: React.ComponentType | null,
  NewComponent: React.ComponentType | null
) => {
  return function WithScoreCheckComponent() {
    const { userScore } = useUserScoreQuery()

    if (userScore && userScore <= MAX_SCORE_TO_OPEN_ADA) {
      return NewComponent ? <NewComponent /> : null
    }

    return OldComponent ? <OldComponent /> : null
  }
}

import { fetchRequestWithCookies } from 'api/axios'

const {
  api: { accountBE },
} = __CONFIG__

type AdaScoreResponse = {
  score: number
}

export class AdaApi {
  public get = async () => {
    const url = new URL(accountBE.paths.adaScore, accountBE.base)

    const { data } = await fetchRequestWithCookies<AdaScoreResponse>(url.href)
    return data
  }
}
